import React from "react";
import dia from "../../../assets/img/dia.svg";
import BersoImg from "../../../assets/img/logos/berso.png";
import KlavaImg from "../../../assets/img/logos/klava.png";
import MegoImg from "../../../assets/img/logos/mego.png";
import NokoImg from "../../../assets/img/logos/noko.png";
import NonceptImg from "../../../assets/img/logos/noncept.png";
import PignatoneImg from "../../../assets/img/logos/pignatone.png";
import SpaghettethImg from "../../../assets/img/logos/spaghetti.png";
import GlassEliteImg from "../../../assets/img/logos/theglasselite.png";
import TypicaImg from "../../../assets/img/logos/typica.png";
import YomiImg from "../../../assets/img/logos/yomi.png";
import DoumixImg from "../../../assets/img/logos/doumix.svg";
/* import mascotte from '../../assets/img/mascotte.svg' */
import Marquee from "react-fast-marquee";
const SixthScreen = () => {
  return (
    <section className="event-s6">
      <Marquee className="banner" autoFill="true" direction="right">
        <h1>
          SPONSORS
          <img src={dia} alt=" " draggable="false" />
        </h1>
      </Marquee>
      <div className="sponsors-ctn">
        <div>
          <a href="https://yomi.digital/#/" target="_blank" rel="noreferrer">
            <img src={YomiImg} alt="yomi" draggable="false" />
          </a>
        </div>
        <div>
          <a href="https://mego.tickets/#/" target="_blank" rel="noreferrer">
            <img src={MegoImg} alt="mego" draggable="false" />
          </a>
        </div>
        <div>
          <a href="https://spaghett-eth.com" target="_blank" rel="noreferrer">
            <img src={SpaghettethImg} alt="spaghetteth" draggable="false" />
          </a>
        </div>
        <div>
          <a
            href="https://twitter.com/KlavaWeb3"
            target="_blank"
            rel="noreferrer"
          >
            <img src={KlavaImg} alt="typica" draggable="false" />
          </a>
        </div>
        <div>
          <a
            href="https://www.facebook.com/bersolicodia"
            target="_blank"
            rel="noreferrer"
          >
            <img src={BersoImg} alt="bersó" draggable="false" />
          </a>
        </div>
        <div>
          <a href="https://typica-coffee.com" target="_blank" rel="noreferrer">
            <img src={TypicaImg} alt="typica" draggable="false" />
          </a>
        </div>
        <div>
          <a href="https://theglasselite.com" target="_blank" rel="noreferrer">
            <img src={GlassEliteImg} alt="glass elite" draggable="false" />
          </a>
        </div>
        <div>
          <a href="https://noncept.com" target="_blank" rel="noreferrer">
            <img src={NonceptImg} alt="noncept" draggable="false" />
          </a>
        </div>
        <div>
          <a href="https://nokobike.com" target="_blank" rel="noreferrer">
            <img src={NokoImg} alt="noko bike" draggable="false" />
          </a>
        </div>
        <div>
          <a
            href="http://www.studiopignatone.it/pagine/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={PignatoneImg} alt="studiopignatone" draggable="false" />
          </a>
        </div>
        <div>
          <a href="https://www.doumixmec3.com/" target="_blank" rel="noreferrer">
            <img src={DoumixImg} alt="do u mix" draggable="false" />
          </a>
        </div>
      </div>
    </section>
  );
};
export default SixthScreen;
