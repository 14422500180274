const MessageIcon = () => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 6.4C0 4.15979 0 3.03969 0.435974 2.18404C0.819467 1.43139 1.43139 0.819467 2.18404 0.435974C3.03969 0 4.15979 0 6.4 0H11.6C13.8402 0 14.9603 0 15.816 0.435974C16.5686 0.819467 17.1805 1.43139 17.564 2.18404C18 3.03969 18 4.15979 18 6.4V7.6C18 9.84021 18 10.9603 17.564 11.816C17.1805 12.5686 16.5686 13.1805 15.816 13.564C14.9603 14 13.8402 14 11.6 14H4.41421C4.149 14 3.89464 14.1054 3.70711 14.2929L1.70711 16.2929C1.07714 16.9229 0 16.4767 0 15.5858V14V9V6.4ZM6 4C5.44772 4 5 4.44772 5 5C5 5.55228 5.44772 6 6 6H12C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4H6ZM6 8C5.44772 8 5 8.44772 5 9C5 9.55228 5.44772 10 6 10H9C9.55228 10 10 9.55228 10 9C10 8.44772 9.55228 8 9 8H6Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MessageIcon;
