import React from "react";

const SpeakerBox = ({ src, alt, name, sub, className }) => {
  return (
    <div className={`speaker-box-root ${className}`}>
      <img src={src} alt={alt} />
      <p className="name" dangerouslySetInnerHTML={{ __html: name }}></p>
      <p className="sub">{sub}</p>
    </div>
  );
};

export default SpeakerBox;
