import React from "react";
import dia from "../../../assets/img/dia.svg";
import yomi from "../../../assets/img/friends_yomi.svg";
// import denver from "../../../assets/img/friends_denver.svg"
import Marquee from "react-fast-marquee";
import { useTranslation } from "react-i18next";
import rifai from "../../../assets/img/RIFAILOGO.svg";
import thecryptonomist from "../../../assets/img/cryptonomist.svg";
import decripto from "../../../assets/img/decripto.png";
import ethRome from "../../../assets/img/ethRome.png";
import bolket from "../../../assets/img/friends_bolket.svg";
import klava from "../../../assets/img/friends_klava.png";
import mego from "../../../assets/img/friends_mego.svg";
import spagh from "../../../assets/img/friends_spagh.svg";
import urbe from "../../../assets/img/friends_urbe.png";
import niftyz from "../../../assets/img/niftyz.png";
import sewer from "../../../assets/img/sewer-logo.png";
import citymap from "../../../assets/img/citymap.svg";

const Friends = () => {
  const { t } = useTranslation();

  return (
    <section className="friends event">
      <Marquee className="banner" autoFill="true" direction="right">
        <h1>
          PARTNERS
          <span>
            <img src={dia} alt=" " draggable="false" />
          </span>
        </h1>
      </Marquee>

      <Marquee speed={90} className="banner" autoFill="true" direction="left">
        <div className="banner-loghi">
          <a href="https://yomi.digital/#/" target="_blank" rel="noreferrer">
            <img src={yomi} alt="yomi" draggable="false" />
          </a>
          <a href="https://linktr.ee/urbe.eth" target="_blank" rel="noreferrer">
            <img src={urbe} alt="urbe" draggable="false" />
          </a>
          {/* <a href="https://linktr.ee/w3b3" target='_blank' rel='noreferrer'>
                        <img src={denver} alt="denver" draggable="false" />
                    </a> */}
          <a href="https://mego.tickets/#/" target="_blank" rel="noreferrer">
            <img src={mego} alt="mego" draggable="false" />
          </a>
          <a href="https://spaghett-eth.com" target="_blank" rel="noreferrer">
            <img src={spagh} alt="spagh" draggable="false" />
          </a>
          <a
            href="https://twitter.com/sewer_it"
            target="_blank"
            rel="noreferrer"
          >
            <img src={sewer} alt="sewer" draggable="false" />
          </a>
          <a
            href="https://www.rifaisicilia.com/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={rifai} alt="rifai" draggable="false" />
          </a>
          <a href="https://niftyz.io/" target="_blank" rel="noreferrer">
            <img src={niftyz} alt="niftyz" draggable="false" />
          </a>
          <a href="https://ethrome.org/" target="_blank" rel="noreferrer">
            <img src={ethRome} alt="ethrome" draggable="false" />
          </a>
          <a href="https://bolket.com" target="_blank" rel="noreferrer">
            <img src={bolket} alt="bolket" draggable="false" />
          </a>
          <a
            href="https://twitter.com/KlavaWeb3"
            target="_blank"
            rel="noreferrer"
          >
            <img src={klava} alt="klava" draggable="false" />
          </a>
          <a href="https://decripto.org/" target="_blank" rel="noreferrer">
            <img src={decripto} alt="decripto" draggable="false" />
          </a>
          <a href="https://cryptonomist.ch/" target="_blank" rel="noreferrer">
            <img src={thecryptonomist} alt="cryptonomist" draggable="false" />
          </a>
          <a href="https://www.citymapsicilia.it/catania/" target='_blank' rel='noreferrer'>
            <img src={citymap} alt="citymap" draggable="false" />
          </a>
        </div>
      </Marquee>
    </section>
  );
};

export default Friends;
