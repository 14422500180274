import React, { useEffect, useState } from "react";
/* ASSETS */
/* import mascotte from '../../assets/img/mascotte.svg' */

import Loader from "../../components/Loader";

/* LIBS */
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Friends from "./components/Partners";
import EightScreen from "./screens/EightScreen";
import FifthScreen from "./screens/FifthScreen";
import FirstScreen from "./screens/FirstScreen";
import FourthScreen from "./screens/FourthScreen";
import SecondScreen from "./screens/SecondScreen";
import SeventhScreen from "./screens/SeventhScreen";
import SeventhScreenMobile from "./screens/SeventhScreenMobile";
import SixthScreen from "./screens/SixthScreen";
import ThirdScreen from "./screens/ThirdScreen";

const Event = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    AOS.init();

    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  return (
    <div className="event-page">
      {isLoading && <Loader />}
      <Navbar />
      <FirstScreen />
      <SecondScreen />
      <ThirdScreen />
      <FourthScreen />
      <FifthScreen />
      <Friends />
      <SixthScreen />
      <SeventhScreen />
      <SeventhScreenMobile />
      <EightScreen />
      <Footer />
    </div>
  );
};
export default Event;
