import React from "react";
import { useTranslation } from "react-i18next";
import logo from "../../../assets/img/logo.svg";
import LanguageSelector from "../../../components/LanguageSelector";

const Navbar = ({ scrollToJoin }) => {
  const { t } = useTranslation();

  return (
    <header>
      <div className="row align-items-center m-0 px-5 py-3 w-100 event-header">
        <div className="col-6">
          <img src={logo} alt="ethna logo" draggable="false" />
        </div>
        <div className="col-6 text-end">
          <div className="menu-btn-cnt">
            {/* <button id='discover-btn' style={discBtn} onClick={toggleMenu}>DISCOVER</button>
            <a href='#about' id='about-btn' style={abBtn} onClick={scrollToAbout}>ABOUT</a> */}

            <a
              className="text-uppercase"
              href="https://app.mego.tickets/event/d5ec4714-31c1-41bf-8db0-5eef5f64e8fe"
              id="join-btn"
              target="_blank"
              rel="noreferrer"
            >
              {t("home.buttonText")}
            </a>
            {/* <button id='esc-btn' onClick={toggleMenu} style={escBtn}>X</button> */}
            <LanguageSelector />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
