import React from "react";
/* ASSETS */
/* import mascotte from '../../assets/img/mascotte.svg' */
import { useTranslation } from "react-i18next";
import CircleImg from "../../../assets/img/icons/circle.svg";
import VolcanoImg from "../../../assets/img/icons/volcano.svg";
import MyChip from "../components/MyChip";
const FourthScreen = () => {
  const { t } = useTranslation();
  return (
    <section className="event-s4">
      <div
        className="event-s4-row"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
      >
        <div className="event-s4-col">
          <img src={VolcanoImg} alt="Volcano icon" />
          <MyChip className="event-s4-chip">{t("label.where")}?</MyChip>
          <p>
            Zō Centro Culture
            <br />
            Contemporanee
          </p>
        </div>
        <div className="event-s4-col">
          <img src={CircleImg} alt="Volcano icon" />
          <MyChip className="event-s4-chip" type="secondary">
            {t("label.when")}?
          </MyChip>
          <p dangerouslySetInnerHTML={{ __html: t("event.s4-when") }} />
        </div>
      </div>
    </section>
  );
};
export default FourthScreen;
