import React from "react";
/* import mascotte from '../../assets/img/mascotte.svg' */
import { useTranslation } from "react-i18next";
import AirportImg from "../../../assets/img/icons/airport.svg";
import PedestrianImg from "../../../assets/img/icons/pedestrian.svg";
import TaxiImg from "../../../assets/img/icons/taxi.svg";
import MyButton from "../components/MyButton";
const EightScreen = () => {
  const { t } = useTranslation();
  return (
    <section className="event-s8">
      <h1 dangerouslySetInnerHTML={{ __html: t("event.s8-title") }} />
      <div
        className="event-s8-info-ctn"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
      >
        <div>
          <img src={AirportImg} alt="Airport" />
          <p>{t("event.s8-step-1")}</p>
        </div>
        <div>
          <img src={TaxiImg} alt="Taxi" />
          <p>{t("event.s8-step-2")}</p>
        </div>
        <div>
          <img src={PedestrianImg} alt="Pedestrian" />
          <p>{t("event.s8-step-3")}</p>
        </div>
      </div>
      <MyButton
        link="https://app.mego.tickets/event/d5ec4714-31c1-41bf-8db0-5eef5f64e8fe"
        target="_blank"
        useFade
      >
        {t("home.buttonText")}
      </MyButton>
    </section>
  );
};
export default EightScreen;
