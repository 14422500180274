import React from "react";
import dia from "../../../assets/img/dia.svg";
import AlessandraTugnoloImg from "../../../assets/img/speakers/alessandra_tugnolo.png";
import AndreaPaesanoImg from "../../../assets/img/speakers/andrea_paesano.png";
import AntonioPaglinoImg from "../../../assets/img/speakers/antonio_paglino.png";
import CarloGiulianoImg from "../../../assets/img/speakers/carlo_giuliano.png";
import DavideCostaImg from "../../../assets/img/speakers/davide_costa.png";
import FrancescoVincentiImg from "../../../assets/img/speakers/francesco_vincenti.png";
import GabrieleModicaImg from "../../../assets/img/speakers/gabriele_modica.png";
import GianlucaGrossiImg from "../../../assets/img/speakers/gianluca_grossi.png";
import IreneGiacomelliImg from "../../../assets/img/speakers/irene_giacomelli.png";
import JonaDipaolaImg from "../../../assets/img/speakers/jona_dipaola.png";
import JoseMaranoImg from "../../../assets/img/speakers/jose_marano.png";
import LimoneImg from "../../../assets/img/speakers/limone.png";
import LivioLombardoImg from "../../../assets/img/speakers/livio_lombardo.png";
import MarcoCastignoliImg from "../../../assets/img/speakers/marco_castignoli.png";
import MarcoMoshiImg from "../../../assets/img/speakers/marco_moshi.png";
import MarcoPignatoneImg from "../../../assets/img/speakers/marco_pignatone.png";
import MarcoTullioGiordanoImg from "../../../assets/img/speakers/marco_tullio_giordano.png";
import MariaMagenesImg from "../../../assets/img/speakers/maria_magenes.png";
import MatteoTambussiImg from "../../../assets/img/speakers/matteo_tambussi.png";
import MirkoZichichiImg from "../../../assets/img/speakers/mirko_zichichi.png";
import QjWangImg from "../../../assets/img/speakers/qj_wang.png";
import RaffaeleIzziaImg from "../../../assets/img/speakers/raffaele_izzia.png";
import RobertoDianoImg from "../../../assets/img/speakers/roberto_diano.png";
import SalvatoreFurnariImg from "../../../assets/img/speakers/salvatore_furnari.png";
import SebastianoCataudoImg from "../../../assets/img/speakers/sebastiano_cataudo.png";
import TBAImg from "../../../assets/img/speakers/tba.png";
import VittorioMinacoriImg from "../../../assets/img/speakers/vittorio_minacori.png";
import DaniloGiudiceImg from "../../../assets/img/speakers/danilo_giudice.png";
import FrancescoBianchiImg from "../../../assets/img/speakers/francesco_bianchi.png";


/* import mascotte from '../../assets/img/mascotte.svg' */
import Marquee from "react-fast-marquee";
import { useTranslation } from "react-i18next";
import SpeakerBox from "../components/SpeakerBox";
const FifthScreen = () => {
  const { t } = useTranslation();
  return (
    <section className="event-s5">
      <Marquee className="banner" autoFill="true" direction="right">
        <h1>
          SPEAKERS
          <img src={dia} alt=" " draggable="false" />
        </h1>
      </Marquee>
      <div className="speakers-ctn">
       <div>
          <SpeakerBox
            src={FrancescoBianchiImg}
            alt="Francesco Bianchi"
            name="FRANCESCO<br/>BIANCHI"
            sub="@Idle / SpaghettETH"
          />
        </div>
        <div>
          <SpeakerBox
            src={MarcoCastignoliImg}
            alt="Marco Castignoli"
            name="MARCO<br/>CASTIGNOLI"
            sub="@Sourcify / SpaghettETH"
          />
        </div>
        <div>
          <SpeakerBox
            src={SebastianoCataudoImg}
            alt="Sebastiano Cataudo"
            name="SEBASTIANO<br/>CATAUDO"
            sub="@YOMI / MEGO Tickets"
          />
        </div>
        <div>
          <SpeakerBox
            src={DavideCostaImg}
            alt="Davide Costa"
            name="DAVIDE<br/>COSTA"
            sub="@Quadrans"
          />
        </div>
        <div>
          <SpeakerBox
            src={JonaDipaolaImg}
            alt="Jona Di Paola"
            name="JONA<br/>DI PAOLA"
            sub="@NFT Artist"
          />
        </div>
        <div>
          <SpeakerBox
            src={RobertoDianoImg}
            alt="Roberto Diano"
            name="ROBERTO<br/>DIANO"
            sub="@YOMI / MEGO Tickets"
          />
        </div>
        <div>
          <SpeakerBox
            src={SalvatoreFurnariImg}
            alt="Salvatore Furnari"
            name="SALVATORE<br/>FURNARI"
            sub="@Studio Lener & Partners"
          />
        </div>
        <div>
          <SpeakerBox
            src={IreneGiacomelliImg}
            alt="Irene Giacomelli"
            name="IRENE<br/>GIACOMELLI"
            sub="@FilOz"
          />
        </div>
        <div>
          <SpeakerBox
            src={MarcoTullioGiordanoImg}
            alt="Marco Tullio Giordano"
            name="MARCO TULLIO<br/>GIORDANO"
            sub="@42 Law Firm"
          />
        </div>
        <div>
          <SpeakerBox
            src={DaniloGiudiceImg}
            alt="Danilo Giudice"
            name="DANILO<br/>GIUDICE"
            sub="@Klava"
          />
        </div>
        <div>
          <SpeakerBox
            src={CarloGiulianoImg}
            alt="Carlo Giuliano"
            name="CARLO<br/>GIULIANO"
            sub="@PixLex"
          />
        </div>
        <div>
          <SpeakerBox
            src={MarcoMoshiImg}
            alt="Marco Grendel"
            name="MARCO<br/>GRENDEL"
            sub="_"
          />
        </div>
        <div>
          <SpeakerBox
            src={GianlucaGrossiImg}
            alt="Gianluca Grossi"
            name="GIANLUCA<br/>GROSSI"
            sub="@Bitcoin Enthusiast / Criptovaluta.it"
          />
        </div>
        <div>
          <SpeakerBox
            src={RaffaeleIzziaImg}
            alt="Raffaele Izzia"
            name="RAFFAELE<br/>IZZIA"
            sub="@ReFi Italia"
          />
        </div>
        <div>
          <SpeakerBox
            src={LimoneImg}
            alt="Limone.eth"
            name="LIMONE.ETH"
            sub="@ETHRome / Urbe.eth"
          />
        </div>
        <div>
          <SpeakerBox
            src={LivioLombardoImg}
            alt="Livio Lombardo"
            name="LIVIO<br/>LOMBARDO"
            sub="@MEGO Tickets"
          />
        </div>
        <div>
          <SpeakerBox
            src={MariaMagenesImg}
            alt="Maria Magenes"
            name="MARIA<br/>MAGENES"
            sub="@Summer.fi / SpaghettETH"
          />
        </div>
        <div>
          <SpeakerBox
            src={JoseMaranoImg}
            alt="Jose Marano"
            name="JOSE<br/>MARANO"
            sub="@Regione Sicilia"
          />
        </div>
        <div>
          <SpeakerBox
            src={VittorioMinacoriImg}
            alt="Vittorio Minacori"
            name="VITTORIO<br/>MINACORI"
            sub="@Noncept"
          />
        </div>
        <div>
          <SpeakerBox
            src={GabrieleModicaImg}
            alt="Gabriele Modica"
            name="GABRIELE<br/>MODICA"
            sub="@NFT Artist"
          />
        </div>
        <div>
          <SpeakerBox
            src={AndreaPaesanoImg}
            alt="Andrea Paesano"
            name="ANDREA<br/>PAESANO"
            sub="@NapulETH"
          />
        </div>
        <div>
          <SpeakerBox
            src={AntonioPaglinoImg}
            alt="Antonio Paglino"
            name="ANTONIO<br/>PAGLINO"
            sub="@Rifai Sicilia"
          />
        </div>
        <div>
          <SpeakerBox
            src={MarcoPignatoneImg}
            alt="Marco Pignatone"
            name="MARCO<br/>PIGNATONE"
            sub="@Studio Pignatone"
          />
        </div>
        <div>
          <SpeakerBox
            src={MatteoTambussiImg}
            alt="Matteo Tambussi"
            name="MATTEO<br/>TAMBUSSI"
            sub="@Stake Capital / SpaghettETH"
          />
        </div>
        <div>
          <SpeakerBox
            src={AlessandraTugnoloImg}
            alt="Alessandra Tugnolo"
            name="ALESSANDRA<br/>TUGNOLO"
            sub="@The Glass Elite"
          />
        </div>
        <div>
          <SpeakerBox
            src={FrancescoVincentiImg}
            alt="Francesco Vincenti"
            name="FRANCESCO<br/>VINCENTI"
            sub="@Digitalia"
          />
        </div>
        <div>
          <SpeakerBox
            src={QjWangImg}
            alt="Qj Wang"
            name="QJ<br/>WANG"
            sub="@Ethereum Community Fund"
          />
        </div>
        <div>
          <SpeakerBox
            src={MirkoZichichiImg}
            alt="Mirko Zichichi"
            name="MIRKO<br/>ZICHICHI"
            sub="@IOTA Foundation"
          />
        </div>
        <div>
          <SpeakerBox src={TBAImg} alt="TBA" name="WHO'S NEXT?" sub="" />
        </div>
      </div>
    </section>
  );
};
export default FifthScreen;
