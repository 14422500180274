import React from "react";
/* ASSETS */
import EthnaBig from "../../../assets/img/svg/EthnaBig";
/* import mascotte from '../../assets/img/mascotte.svg' */

/* LIBS */
import { useTranslation } from "react-i18next";
import MyButton from "../components/MyButton";
import Volcano from "../components/Volcano";
const FirstScreen = () => {
  const { t } = useTranslation();
  return (
    <section className="event-s1">
      <Volcano />
      <section className="event">
        <div className="ethna" style={{ width: "90%" }}>
          <EthnaBig />
          <h3 className="event-info">{t("event.info")}</h3>
          <section className="container-fluid event-cta p-0">
            <div className="row w-100">
              <div className="col-12">
                {" "}
                <p className="apply-as-text">{t("event.s1-apply-as")}</p>
              </div>
              <div className="col-12 mx-auto apply-ctn">
                <MyButton
                  link="https://beaspeaker.ethna.rocks/"
                  target="_blank"
                >
                  {t("home.buttonSpeaker")}
                </MyButton>
                <MyButton
                  link="https://beapartner.ethna.rocks/"
                  target="_blank"
                >
                  {t("home.buttonPartner")}
                </MyButton>
              </div>
            </div>
          </section>
        </div>
      </section>
    </section>
  );
};
export default FirstScreen;
