import React from "react";
import ClockIcon from "../../../assets/img/svg/ClockIcon";
import MessageIcon from "../../../assets/img/svg/MessageIcon";
import PinIcon from "../../../assets/img/svg/PinIcon";
const ScheduleCard = ({
  blur,
  title,
  time,
  place,
  className,
  type = "primary",
}) => {
  let cardStyle = "schedule-card-primary";
  if (type === "secondary") {
    cardStyle = "schedule-card-secondary";
  } else if (type === "tertiary") {
    cardStyle = "schedule-card-tertiary";
  }

  return (
    <div className={`schedule-card ${cardStyle} ${className}`}>
      <div className={`${blur ? "blur" : ""}`}>
        <div>
          <MessageIcon />
          <span>{title}</span>
        </div>
        <div>
          <ClockIcon />
          <span>{time}</span>
        </div>
        <div>
          <PinIcon />
          <span>{place}</span>
        </div>
      </div>
      {blur && (
        <div className="tba">
          <span>TBA...</span>
        </div>
      )}
    </div>
  );
};

export default ScheduleCard;
