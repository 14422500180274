const EthnaBig = () =>{
    return(
    <svg className='ethna-big' width="939" height="209" viewBox="0 0 939 209" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.978516 78.0762H136.275V107.775H40.5774V127.162H133.882V156.993H40.5774V178.443H137.925V208.819H0.978516" fill="white"/>
        <path d="M216.082 209V109.408H161.023V78.6533H311.714V109.425H256.523V209H216.082Z" fill="white"/>
        <path d="M337.223 208.769V78.6533H377.168V126.205H463.098V78.6533H503.027V208.785H463.098V157.505H377.168V208.769H337.223Z" fill="white"/>
        <path d="M536.902 78.6533H581.765L663.487 160.689V78.6533H702.904V209H663.421L576.501 122.674V209H536.902V78.6533Z" fill="white"/>
        <path d="M870.258 78.0264H791.406L726.629 208.818H767.63L779.048 184.943L793.403 155.459L816.238 108.353H844.766L868.74 155.525L883.589 185.009L895.65 208.884H938.945L870.258 78.0264Z" fill="white"/>
        <path d="M216.082 209V109.408H161.023V78.6533H311.714V109.425H256.523V209H216.082Z" fill="white"/>
        <path d="M337.223 208.769V78.6533H377.168V126.205H463.098V78.6533H503.027V208.785H463.098V157.505H377.168V208.769H337.223Z" fill="white"/>
        <path d="M536.902 78.6533H581.765L663.487 160.689V78.6533H702.904V209H663.421L576.501 122.674V209H536.902V78.6533Z" fill="white"/>
        <path d="M870.258 78.0264H791.406L726.629 208.818H767.63L779.048 184.943L793.403 155.459L816.238 108.353H844.766L868.74 155.525L883.589 185.009L895.65 208.884H938.945L870.258 78.0264Z" fill="white"/>
        <path d="M136.275 29.6992V0H0.978516V29.6992H136.275Z" fill="white"/>
    </svg>

    )
}

export default EthnaBig