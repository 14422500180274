import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useRef } from "react";
import volc from "../../../assets/img/volcano_black.svg";

gsap.registerPlugin(ScrollTrigger);

const Volcano = () => {
  const volcanoRef = useRef(null);
  const bgRef = useRef(null);
  const homeRef = useRef(null);
  const tl = useRef(null);
  const container = useRef(null);

  useGSAP(
    () => {
      const volcanoEl = volcanoRef.current;
      const bgEl = bgRef.current;
      const home = homeRef.current;

      tl.current = gsap.timeline({ paused: true });

      tl.current.to(volcanoEl, {
        bottom: 0,
        duration: 2,
        ease: "power2.out",
        scrollTrigger: {
          trigger: bgEl,
          start: "top top",
          end: "+=200vh",
          scrub: true,
        },
      });

      tl.current.to(home, {
        duration: 1,
        ease: "power2.out",
        scrollTrigger: {
          trigger: "body",
          start: "top top",
          end: "+=200vh",
          scrub: true,
          pin: true,
          pinType: "fixed",
        },
      });
    },
    { scope: container }
  );

  return (
    <>
      <div className="volcano-cnt" ref={container}>
        <div className="bg" ref={bgRef}></div>
        <div className="volc-wr">
          <img src={volc} alt=" " className="volcano" ref={volcanoRef} />
        </div>
      </div>
    </>
  );
};

export default Volcano;
