import React from "react";
import LavaImg from "../../../assets/img/generics/lava_event.svg";
import LavaMobImg from "../../../assets/img/generics/lava_event_mob.svg";
import logo_ext from "../../../assets/img/logo_ext.svg";

import { useTranslation } from "react-i18next";
import Telegram from "../../../assets/img/svg/Telegram";
import Tw from "../../../assets/img/svg/Tw";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="event-footer">
      <img
        src={LavaImg}
        alt=" "
        className="event-footer-lava desk"
        draggable="false"
      />
      <img
        src={LavaMobImg}
        alt=" "
        className="event-footer-lava mob"
        draggable="false"
      />
      <div className="row w-100 m-0 contact-cnt">
        <div className="col-sm-12 col-xl-6">
          <img
            src={logo_ext}
            className="event-footer-logo"
            alt="logo ethna"
            draggable="false"
          />
          <p className="event-footer-phrase">
            Together, we shape
            <br />
            tomorrow!
          </p>
        </div>

        <div className="col-sm-6 col-xl-3 mail-cnt desk">
          <p className="txt-b">{t("event.footer-discover")}</p>
          <div className="grad-btn-cnt">
            <a
              href="ETHNA_PITCHDECK_2024.pdf"
              target="_blank"
              download="ETHNA_PITCHDECK_2024.pdf"
            >
              <div className="grad-btn btn-1 d-none d-xl-block"></div>
              <div className="grad-btn btn-2 d-none d-xl-block"></div>
              <div className="grad-btn btn-3">
                <p>pitch deck</p>
              </div>
            </a>
          </div>
        </div>

        <div className="col-sm-6 col-xl-3 mail-cnt desk">
          <p className="txt-b">{t("event.footer-contact")}</p>
          <div className="grad-btn-cnt">
            <a href="mailto:lava@ethna.rocks">
              <div className="grad-btn btn-1 d-none d-xl-block"></div>
              <div className="grad-btn btn-2 d-none d-xl-block"></div>
              <div className="grad-btn btn-3">
                <p>lava@ethna.rocks</p>
              </div>
            </a>
          </div>
          <p className="txt-b mt-5">{t("event.footer-follow")}</p>
          <div className="social event-footer-social justify-content-center justify-content-xl-start">
            <a
              href="https://x.com/ethna_rocks"
              target="_blank"
              rel="noreferrer"
            >
              <Tw />
            </a>
            <a href="https://t.me/ethnarocks" target="_blank" rel="noreferrer">
              <Telegram />
            </a>
          </div>
        </div>

        <div className="grad-btn-cnt mail-cnt mob mb-3 col-sm-4">
          <p className="txt-b">{t("event.footer-contact")}</p>
          <div className="social event-footer-social d-flex justify-content-center">
            <a
              href="https://x.com/ethna_rocks"
              target="_blank"
              rel="noreferrer"
            >
              <Tw />
            </a>
            <a href="https://t.me/ethnarocks" target="_blank" rel="noreferrer">
              <Telegram />
            </a>
          </div>

          <p className="txt-b">{t("event.footer-contact")}</p>
          <a className="email-btn" href="mailto:lava@ethna.rocks">
            <div className="grad-btn">
              <p>lava@ethna.rocks</p>
            </div>
          </a>

          <p className="txt-b mt-4">{t("event.footer-discover")}</p>
          <a
            href="ETHNA_PITCHDECK_2024.pdf"
            target="_blank"
            download="ETHNA_PITCHDECK_2024.pdf"
            className="pitch-btn"
          >
            <div className="grad-btn btn-3">
              <p>pitch deck</p>
            </div>
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <p className="made-by">
            {t("footer.madeWithLove")}{" "}
            <a
              className="text-decoration-underline"
              href="https://yomi.digital/#/"
              target="_blank"
              rel="noreferrer"
            >
              YOMI
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
