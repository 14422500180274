const PinIcon = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.40021 19.805C11.1143 18.9311 17.75 15.1354 17.75 8.75C17.75 3.91751 13.8325 0 9 0C4.16751 0 0.25 3.91751 0.25 8.75C0.25 15.1354 6.88566 18.9311 8.59979 19.805C8.85356 19.9344 9.14644 19.9344 9.40021 19.805ZM9 12.5C11.0711 12.5 12.75 10.8211 12.75 8.75C12.75 6.67893 11.0711 5 9 5C6.92893 5 5.25 6.67893 5.25 8.75C5.25 10.8211 6.92893 12.5 9 12.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PinIcon;
