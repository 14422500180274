import React from "react";

const MyButton = ({ children, link, target, className, useFade = false }) => {
  const btnContent = (
    <a href={link} target={target} rel="noreferrer">
      <div className="grad-btn btn-1"></div>
      <div className="grad-btn btn-2"></div>
      <div className="grad-btn btn-4"></div>
      <div className="grad-btn btn-5"></div>
      <div className="grad-btn btn-3">
        <p>{children}</p>
      </div>
    </a>
  );

  return useFade ? (
    <div
      className={`grad-btn-cnt mt-0 ${className}`}
      data-aos="fade-up"
      data-aos-offset="200"
      data-aos-delay="95"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
    >
      {btnContent}
    </div>
  ) : (
    <div className={`grad-btn-cnt mt-0 ${className}`}>{btnContent}</div>
  );
};

export default MyButton;
