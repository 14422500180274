import React from 'react';
import LoaderImg from "../assets/img/svg/LoaderImg"

const Loader =() =>{
    return(
        <>
        <div className="loader">
        <LoaderImg />
        </div>
        </>
    )

}

export default Loader