import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { I18nextProvider } from "react-i18next";
import "../src/assets/fonts/stylesheet.css";
import "../src/assets/style/global.scss";
import "../src/assets/style/resp.scss";
import i18n from "./i18n"; // Importa la configurazione di i18n
import Event from "./pages/event/Event";

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <div className="App container-fluid p-0">
        <Event />
      </div>
    </I18nextProvider>
  );
}

export default App;
