import React from "react";

const MyChip = ({
  children,
  className,
  type = "primary",
  style = "outline",
  onClick,
}) => {
  let chipType = "my-chip-primary";
  if (type === "secondary") {
    chipType = "my-chip-secondary";
  } else if (type === "tertiary") {
    chipType = "my-chip-tertiary";
  }
  let chipStyle = "my-chip-outline";
  if (style === "filled") {
    chipStyle = "my-chip-filled";
  }

  return (
    <span
      onClick={() => {
        if (onClick) onClick();
      }}
      className={`my-chip ${chipType} ${chipStyle} ${className}`}
    >
      {children}
    </span>
  );
};

export default MyChip;
