import React from "react";
/* ASSETS */
/* import mascotte from '../../assets/img/mascotte.svg' */

/* LIBS */
import { useTranslation } from "react-i18next";
import mascotte from "../../../assets/img/mascotte.gif";
const SecondScreen = () => {
  const { t } = useTranslation();
  return (
    <section className="row what event-s2">
      <div className="col-sm-12 col-md-8">
        {/* <h3>Ethna is a place for web3 enthusiasts, businesses, professionals, and anyone</h3> */}
        <h3
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          dangerouslySetInnerHTML={{ __html: t("event.s2-description") }}
        ></h3>
      </div>
      <div className="col-sm-12 col-lg-4">
        <img src={mascotte} alt="ethna mascotte" draggable="false" />
      </div>
    </section>
  );
};
export default SecondScreen;
