import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const toggleLanguage = () => {
    const newLanguage = i18n.language === 'en' ? 'it' : 'en'; // Se la lingua corrente è inglese, cambia in italiano; altrimenti, cambia in inglese
    i18n.changeLanguage(newLanguage);
  };

  return (
    <div>
      <button className='ms-3 text-uppercase' onClick={toggleLanguage}>
        {i18n.language === 'en' ? 'It' : 'En'}
      </button>
    </div>
  );
};

export default LanguageSelector;
