import React from "react";
/* import mascotte from '../../assets/img/mascotte.svg' */
import { useTranslation } from "react-i18next";
import EthnaOffBgImg from "../../../assets/img/generics/ethna_off_bg.svg";
import EthnaOffBgMdImg from "../../../assets/img/generics/ethna_off_bg_md.svg";
import MyChip from "../components/MyChip";
import ScheduleCard from "../components/ScheduleCard";
const SeventhScreen = () => {
  const { t } = useTranslation();

  return (
    <section className="event-s7 d-none d-md-block">
      <div className="event-s7-schedule-tab-ctn">
        <MyChip className="event-s7-chip">AM</MyChip>
        <div className="event-s7-schedule-cards-ctn">
          <ScheduleCard
            title="Breakfast"
            time="9:30-11:30"
            place="ĒTHNA OFF"
            type="tertiary"
          />
          <ScheduleCard
            title="Welcome to ĒTHNA"
            time="10:00"
            place="Main Stage"
          />
          <ScheduleCard
            title="ETH Communities in Italia & 'WTF is Blockchain?'"
            time="10:10-11:15"
            place="Main Stage"
          />
          <ScheduleCard
            title="Exploring Business Opportunities Through Tokenization"
            time="11:15-11:45"
            place="Main Stage"
          />
          <ScheduleCard
            title="Offerta di utility token al pubblico e conformità dei white paper al Regolamento MiCA - 3 case studies"
            time="11:45-12:10"
            place="Main Stage"
          />
          <ScheduleCard
            title="Lunch Time"
            time="12:00-14:30"
            place="SpiazZō"
            type="tertiary"
          />
          <ScheduleCard
            title="Network State, una storia italiana"
            time="12:10-12:55"
            place="Main Stage"
          />
          <ScheduleCard
            title="Decentralised Data Storage"
            time="12:55-13:20"
            place="Main Stage"
          />
          <div
            style={{
              width: "32.5%",
            }}
          />
        </div>
      </div>
      <div className="event-s7-schedule-tab-ctn">
        <MyChip className="event-s7-chip" type="secondary">
          PM
        </MyChip>
        <div className="event-s7-schedule-cards-ctn">
          <ScheduleCard
            title="Ma che cos’è la Finanza Decentralizzata (DeFi)?"
            time="13:20-13:50"
            place="Main Stage"
            type="secondary"
          />
          <ScheduleCard
            title="La Blockchain, volàno per l’agricoltura siciliana"
            time="13:50-14:20"
            place="Main Stage"
            type="secondary"
          />
          <ScheduleCard
            title="Decentralizing the world, the Quadrans effect"
            time="14:20-14:50"
            place="Main Stage"
            type="secondary"
          />
          <ScheduleCard
            title="Workshop: Intro a Solidity"
            time="14:45-15:30"
            place="Workshop Room"
            type="tertiary"
          />
          <ScheduleCard
            title="AI & BC"
            time="14:50-15:15"
            place="Main Stage"
            type="secondary"
          />
          <ScheduleCard
            title="WEB3 oltre il digitale. È possibile?"
            time="15:15-15:35"
            place="Main Stage"
            type="secondary"
          />
          <ScheduleCard
            title="NFT Ticketing Revolution?"
            time="15:35-15:55"
            place="Main Stage"
            type="secondary"
          />
          <ScheduleCard
            title="Workshop: Finanza Decentralizzata (DeFi) per principianti"
            time="15:40-16:10"
            place="Workshop Room"
            type="tertiary"
          />
          <ScheduleCard
            title="(Im)materiale nell’arte"
            time="15:55-16:25"
            place="Main Stage"
            type="secondary"
          />
          <ScheduleCard
            title="Workshop: Farcaster, il social decentralizzato"
            time="16:15-17:00"
            place="Workshop Room"
            type="tertiary"
          />
          <ScheduleCard
            title="CryptoArt as a full time job"
            time="16:25-16:45"
            place="Main Stage"
            type="secondary"
          />
          <ScheduleCard
            title="WEB3 Marketing"
            time="16:45-17:05"
            place="Main Stage"
            type="secondary"
          />
          <ScheduleCard
            title="Sviluppo di un progetto crypto e compliance legale"
            time="17:05-17:35"
            place="Main Stage"
            type="secondary"
          />
          <ScheduleCard
            title="Workshop: ReFI"
            time="17:15-18:00"
            place="Workshop Room"
            type="tertiary"
          />
          <ScheduleCard
            title="DAO Aspetti Legali"
            time="17:35-18:00"
            place="Main Stage"
            type="secondary"
          />
          <ScheduleCard
            title="Move: the Language for Secure Next Gen Smart Contracts"
            time="18:00-18:30"
            place="Main Stage"
            type="secondary"
          />
          <ScheduleCard
            title="Bitcoin: l’erba del vicino è sempre piu verde"
            time="18:30-19:00"
            place="Main Stage"
            type="secondary"
          />
          <div
            style={{
              width: "32.5%",
            }}
          />
        </div>
      </div>
      <div className="event-s7-ethna-off-ctn">
        <img
          src={EthnaOffBgImg}
          className="d-none d-xl-block"
          alt="Background"
        />
        <img
          src={EthnaOffBgMdImg}
          className="d-block d-xl-none"
          alt="Background"
        />
        <div className="event-s7-ethna-off-info">
          <MyChip type="tertiary">ĒTHNA OFF</MyChip>
          <p className="event-s7-ethna-off-desc">
            {t("event.s7-ethna-off-description")}
          </p>
        </div>
      </div>
    </section>
  );
};
export default SeventhScreen;
